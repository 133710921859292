<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<!-- <el-button type="primary" round icon="el-icon-back" size="mini" @click="$router.push({ name: 'WhCustomerSkuInventoriesChangeStock' })">
									SKU<span>{{$t('i18nn_de53ab10322cd38a')}}</span>
								</el-button> -->
								<span class="tct_tit">库存盘点</span>
								<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
									<el-button type="warning" circle icon="el-icon-download" size="mini"
										@click="exportExcel()"></el-button>
								</el-tooltip>
								
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<ul class="filterConList">
							
							<li>
								<span>{{$t('hytxs0000060')}}</span>
								<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
							</li>
							<li>
								<span>{{$t('c944a6686d996ab3')}}</span>
								<whNoSelect @changeData="changWhNo"></whNoSelect>
							</li>
							
							<li>
								<span>库位</span>
								<el-input type="text" v-model="filterData.place" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50"
									:placeholder="'请输入(精确搜索)'" style="width: 180px;" />
							</li>
						</ul>
						<ul class="filterConList">
							
							<li>
								<span>起始库存数</span>
								<el-input-number v-model="filterData.minStock" size="small" controls-position="right"></el-input-number>
							</li>
							<li>
								<span>结束库存数</span>
								<el-input-number v-model="filterData.maxStock" size="small" controls-position="right"></el-input-number>
							</li>
							
							<li>
								<span>SKU</span>
								<el-input type="text" v-model="filterData.goodsSku" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50"
									:placeholder="'请输入(精确搜索)'" style="width: 180px;" />
							</li>
							<!-- <li>
								<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50"
									:placeholder="$t('custom_enterfuzzysearch')" style="width: 180px;" />
							</li> -->

							<li>
								<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
									{{ $t('1e7246dd6ccc5539') }}
								</el-button>
							</li>
						</ul>
					</div>
				</div>

				<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
					:height="$store.state.tableMaxHeight4" @row-click="handleCurrentChange"
					@selection-change="handleSelectionChange" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>

					<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="whNo" :label="$t('c944a6686d996ab3')"></el-table-column>
					
					<el-table-column prop="place" :label="$t('hytxs0000035')"></el-table-column>
					
					<el-table-column prop="goodsSku" label="SKU"></el-table-column>
					
					<el-table-column prop="stock" :label="'数量'"></el-table-column>
					
					<!-- <el-table-column prop="inWhDate" :label="$t('i18nn_4647ba3fc60f0151')"></el-table-column> -->
					<!-- <el-table-column prop="inWhSource" :label="'inWhSource'"></el-table-column> -->
					<!-- <el-table-column prop="receiptNo" :label="$t('i18nn_6c86c295863f5218')"></el-table-column> -->
					<!-- <el-table-column prop="statusName" :label="$t('6cdece641436d7ab')">
						<template slot-scope="scope">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</template>
					</el-table-column>
					<el-table-column prop="inWhSourceName" :label="$t('i18nn_61c33362d88eb0ba')">
						<template slot-scope="scope">
							{{ $Utils.i18nKeyText(scope.row,'inWhSourceName') }}
						</template>
					</el-table-column> -->
					
					
					
					

					<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
					
					
					<!-- <el-table-column prop="createTime" :label="$t('2594059036dfede5')" width="200">
						<template slot-scope="scope">
							<div>
								<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{scope.row.createTime}}</div>
								<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{scope.row.updateTime}}</div>
							</div>
						</template>
					</el-table-column> -->

					<!-- <el-table-column :label="$t('93f5ca01b006206c')" width="180px" fixed="right">
						<template slot-scope="scope">
							<div>
								<el-button @click="changeSkuStock($event, scope.row, scope.$index)" type="warning"
									size="mini" icon="el-icon-edit">{{$t('i18nn_2140e9438c2d1633')}}</el-button>

							</div>
						</template>
					</el-table-column> -->
				</el-table>
			</div>
			<div class="tableConPagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>



		<!---->
		<!-- <el-dialog :title="$t('i18nn_b6ff7f85ee235684')" append-to-body :close-on-click-modal="false" :visible.sync="dialogStockVisible"
			width="1000px">
			<div style="">
				<el-card>
					<div>
						<el-form ref="StockData" :model="StockData" label-width="100px" v-loading="loading"
							:element-loading-text="$t('44bd089554515c4d')">
							<el-form-item :label="$t('i18nn_467c8054005f46e1')" required>
								<el-input-number size="" v-model="StockData.changeStock"></el-input-number>
							</el-form-item>
						</el-form>
					</div>
				</el-card>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogStockVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="submitStock()">{{$t('ad4345dbaabe1479')}}</el-button>
			</div>
		</el-dialog> -->
		
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhStockCheckPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	
	import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
	
	export default {
		components: {
			// WhInGoods
			whNoSelect,
			cusSelFuzzy,
			whExcelCustom
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	}
		// },
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: "",
				excelOption: {},
				
				//上传图片
				// dialogFormVisible: false,
				// imgId: this.$store.getters.getUserInfo.id,
				// openRowData: {},
				// openRowIndex: {},
				// dialogDetVisible: false,
				// detData: [],
				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				// dialogStockVisible: false,
				// StockData:{
				// 	"changeStock":"",
				// 	"remark":""
				// },
				// StockData: {
				// 	id:"",
				// 	// "userId": "",
				// 	// "goodsSku": "",
				// 	"changeStock": "",
				// 	// changeType: "",
				// 	// whNo: "",
				// 	// remark: ""
				// },
				//打开入库选择框
				// dialogSelVisible: false,

				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading_load: false,
				loading: false,
				loading_det: false,
				//表格数据
				tableData: [],
				//缓存，原数据
				// tableDataCatch: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				// cus_loading: false,

				selectOption: {
					// wh_no: [],
					// cusList: [],
					// wh_size_unit: [],
					// wh_change_stock_type: [],
					// statusList: [
					//   {
					//     value: '',
					//     label: this.$t('16853bda54120bf1')
					//   },
					//   {
					//     value: '1',
					//     label: this.$t('hytxs0000027')
					//   },
					//   {
					//     value: '2',
					//     label: this.$t('i18nn_87619aa3fd09e8c2')
					//   }
					// ]
				},

				filterData: {
					orderBy: 'create_time', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// status: '',
					// plNo: '',
					// cusNo: '',
					// r_userId: '',
					whNo: '',
					userId: '',
					keyword: "",
					place:'',
					wh_size_unit: '',
					goodsSku: '',
					minStock: undefined,
					maxStock: undefined,
					// goodsName: ''
				}
			};
		},
		// watch: {
		// 	$route(to, from) {
		// 		if (this.$route.query && this.$route.query.userId) {
		// 			this.filterData.userId = this.$route.query.userId;
		// 		}
		// 		this.initData();
		// 	}
		// },
		activated() {
			if (this.$route.query && this.$route.query.userId) {
				this.filterData.userId = this.$route.query.userId;
			}
			this.initData();
		},
		//创建时
		created() {
			// if(this.isSel){
			//   this.filterData.status = '2';
			// }
			// if (this.$route.query && this.$route.query.userId) {
			// 	this.filterData.userId = this.$route.query.userId;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			//店铺默认可选数据
			// this.getCusListData('');
			// this.getWhNoData();
			// this.getDicData();
			//数据字典
			// getDicData(['wh_change_stock_type'],
			// 	(data)=>{
			// 		this.selectOption.wh_change_stock_type = data['wh_change_stock_type'];
			// });
		},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				// if (this.filterData.userId) {
					this.getPageData();
				// } else {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// }
				// 部门数据
				// this.getProvinceData();
				// this.getDetData("");
			},
			//导出 excel
			exportExcel() {
			
				let columns = [{
						title: this.$t('hytxs0000060'),
						key: 'cusName'
					},
					{
						title: this.$t('c944a6686d996ab3'),
						key: 'whNo'
					},
					{
						title: this.$t('hytxs0000035'),
						key: 'place'
					},
					{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: '数量',
						key: 'stock'
					},
					// {
					// 	title: this.$t('i18nn_4647ba3fc60f0151'),
					// 	key: 'inWhDate'
					// },
					// {
					// 	title: this.$t('i18nn_6c86c295863f5218'),
					// 	key: 'receiptNo'
					// },
					// {
					// 	title: this.$t('6cdece641436d7ab'),
					// 	key: 'statusName'
					// },
					// {
					// 	title: this.$t('i18nn_61c33362d88eb0ba'),
					// 	key: 'inWhSourceName'
					// },
					
					{
						title: this.$t('15b3627faddccb1d'),
						key: 'remark'
					},
					// {
					// 	title: this.$t('i18nn_e8564657bbe9ca53'),
					// 	key: 'createTime'
					// },
					// {
					// 	title: this.$t('i18nn_e1911e9360047dcb'),
					// 	key: 'updateTime'
					// },
				];
				let Data = this.tableData;
			
				// if (!this.filterData.userId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				// if (!this.filterData.daterange || this.filterData.daterange.length<2) {
				// 	this.$message.warning(this.$t('i18nn_91e192ea5925de92'));
				// 	return;
				// }
			
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'WhSkuStockLock';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'WhCostStatistics',{height:20});
			},
			changCus(data) {
				console.log("changCus", data);
				// this.filterData.cusNo = data.cusNo;
				this.filterData.userId = data.userId;
				this.initData();
			},
			
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},

			
			//分页的筛选项数据
			pageFilterData() {
				return {
					// states: this.filterData.status ? this.filterData.status : null,
					// plNo: this.filterData.plNo ? this.filterData.plNo : null,
					// cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
					userId: this.filterData.userId,
					place: this.filterData.place ? this.filterData.place : null,
					// keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// sizeUnit: this.filterData.wh_size_unit ? this.filterData.wh_size_unit : null,
					// packageNo: this.filterData.packageNo ? this.filterData.packageNo : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					
					// "userId":"",
					// "whNo":"",
					"minStock":this.filterData.minStock ? this.filterData.minStock : null,
					"maxStock":this.filterData.maxStock ? this.filterData.maxStock : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhStockCheckPageList, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//提交信息
			postData(url, formData, callBack) {
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callBack();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',

							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning',

						});
					});
			},
			
		},

	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
